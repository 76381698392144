<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { valueGetters } from "~/components/fragment-sets/basic_component/util";
  import type { ManageAPI } from "~/manage-api/types";
  import type { Link } from "~/components/field-utils";
  import NewsCard from './NewsCard.svelte';
  import StoreCardFrontPage from "./StoreCardFrontPage.svelte";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  let _class = "";
  export { _class as class };
  const stores_context = getStoresContext(request_store_key);
  const { events, reverse_router, T, stores, scdata, locale } = stores_context;
  const { getLink, getText } = valueGetters(stores_context);

  $: left_lift_title  = $locale && getText("left_lift_title", fragment);
  $: right_lift_title  = $locale && getText("right_lift_title", fragment);
  $: newStoresIDS = $locale && getText("new_stores_ids", fragment);
  $: readMoreButtonLeft = ($locale && getLink("read_more_button_left", fragment)) as Link;
  $: readMoreButtonRight = ($locale && getLink("read_more_button_right", fragment)) as Link;
  $: more_left_href = readMoreButtonLeft?.href || $reverse_router.routePurpose('events collection');
  $: more_right_href = readMoreButtonRight?.href || $reverse_router.routePurpose('stores collection');

  $: t = $T;

  $: news_shown = $events.filter((event) => event.showOnFrontPage).slice(0, 3);

  $: idsArray = newStoresIDS?.trim()?.split(',')?.map(function (item) {
    return parseInt(item, 10);
  }) || [];

  $: chosenStores = idsArray.map((i) => $stores.find((j) => j.id === i));
</script>

<div class="news-and-events {_class}">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-1 col-xl-2 col-12" />
      <div class="col-lg-5 col-xl-4 col-12 mb-5 mb-lg-0 d-flex">
        <!-- Events List -->
        <div class="pt-5 pb-5 news-wrapper w-100">
          {#if left_lift_title}
            <h2 class="font-reliable">{left_lift_title}</h2>
          {/if}
          {#if news_shown && news_shown.length}
            <div class="news-list flex-grow-1 mb-5">
              {#each news_shown as event}
                <div class="card-wrapper">
                  <NewsCard {request_store_key} {event} isSearchResult={false} />
                </div>
              {/each}
            </div>
          {/if}
          <a href={more_left_href} class="mx-auto mt-auto px-4 py-2 purple-outline-button"
            >{readMoreButtonLeft?.text || t`more-current-topics`}
          </a>
        </div>
      </div>
      <div class="col-lg-5 col-xl-4 col-12 d-flex">
        <!-- Stores List -->
        <div class="pt-5 pb-5 store-wrapper w-100">
          {#if right_lift_title}
            <h2 class="font-reliable">{right_lift_title}</h2>
          {/if}
          {#if chosenStores && chosenStores.length}
            <div class="stores-list flex-grow-1 mb-5">
              {#each chosenStores as store}
                <!-- Prevent lift crashing because of incorrect store id provided: -->
                {#if store && store?.id}
                  <div class="card-wrapper front-page">
                    <StoreCardFrontPage 
                      {store}
                      href={$reverse_router.routeStore(store, $scdata)}
                    />
                  </div>
                {/if}
              {/each}
            </div>
          {/if}
          <a href={more_right_href} class="mx-auto mt-auto px-4 py-2 purple-outline-button"
            >{readMoreButtonRight?.text || t`all-shops-and-services`}
          </a>
        </div>
      </div>
      <div class="col-lg-1 col-xl-2 col-12" />
    </div>
  </div>
</div>

<style lang="scss">
  .news-and-events {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media screen and (min-width: 992px) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }
  
  .news-wrapper, .store-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    background-color: #fff;
    height: 100%;

    h2 {
      text-align: center;
      margin-bottom: 32px;
      font-size: 45px;
      text-transform: uppercase;
    }
  }

  .news-list, .stores-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }

  .card-wrapper {
    width: 100%;
    padding: 0.5rem 1.5rem;
    padding-right: 0;

    &.front-page {
      padding-left: 1.5rem;
    }
  }

  :global(.purple-outline-button) {
    display: block;
    width: fit-content;
  }
</style>
